import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import DOMPurify from 'dompurify';
import {
	Row,
	Col,
	Container,
	Form,
	InputGroup,
	Button,
	Tab,
	Nav,
	ButtonToolbar,
	ToggleButton,
	ToggleButtonGroup,
	Image,
	OverlayTrigger,
	Tooltip,
	Badge,
	ThemeProvider,
	Card,
	Alert,
	Navbar,
  } from "react-bootstrap";
import DataService from '../services/DataService';
import {alertError} from '../components/Alerts';
import { ONLINE_PAYMENT_MODES } from '../helpers/globalConstants';
import StripeCheckoutButton from './stripe/StripeCheckoutButton';
const ORDER_TYPES=['DELIVERY','TAKEOUT']
const DELIVER_PREPAIR=['deliver','prepare' ]
const DELIVER_ADDRESS_MESSAGE=['Your order will be delivered to','Please pickup your order from the following address.' ]
const Loading=100
const Payable=1
const Paid=101
const Expired=3
const Failed=102
const PAYWITHMONERIS=103


class DeliveryRequest extends React.Component {
	constructor(props) {

		super(props);
		this.state={cart_details:{},
		status:props.payment_status,
		pay_status:false,
		tipStatus:true,
		tip:0,
		tipPercentage:0,
	profile:{}}
	this.dataHandler = new DataService("restaurants");

		this.cartHandler= new DataService("carts");
	if(props.payment_status==PAYWITHMONERIS)
	{
		this.finishPayCollection("moneris");
	}
		this.cartHandler.setCustomerID()
		this.setStripePayment=this.setStripePayment.bind(this);

	}
	getMonerisPage=()=>{
		let inputParams={}
		
		inputParams['name']=this.props.key_name
		inputParams['cust_id']=this.state.cust_id
		inputParams['order_id']=this.state.cart_details.order_id
		inputParams['total']=(Number(this.state.cart_details['total_amount'])+Number(this.state.tip)).toFixed(2)
		this.dataHandler.getMonerisTicket(inputParams).then((response) => {
		  if(response.data.status=='sucess')
		  {        
		  let currentData = response.data.data;
		  var ticket=currentData.ticket;
		  let inputForm={...this.state.cart_details};
		  inputForm['moneris_ticket']=ticket;
		  inputForm["payment_mode"] = "PAY ONLINE";
		  inputForm['restaurant_name']=this.props.key_name
		  inputForm['payment_status'] = 0;  
		  inputForm['total_amount']=(Number(inputForm['total_amount'])+Number(this.state.tip)).toFixed(2)
		  inputForm['order_id']=this.state.cart_details.order_id
		  this.dataHandler.setCartParams(this.props.key_name,inputForm);		
		  this.setState({cart_details:inputForm,pay_status:"moneris"},()=>{
			                        
			window.loadMOneris(ticket)
			
		  })
  
		 
		
		  }
		  else{
		   alert("Moneris gateway is not responding");
		   
		  }});
	  }
	setTip=(val)=>{
		val=Number(val)
			if((val<0)||(isNaN(val)))
			{
				val=0
			}
		this.setState({tip:val},()=>{
			

			var status=(val>=0);
			this.setState({tipStatus:status})
		})
	}
	componentDidMount()
	{
		if((this.props.payment_status==Loading) ||(this.props.payment_status==Paid) ){
		this.setCustomerParams()
		}
	
	}
	findTipPercentage = (ctip) => {
		var tipPercentage=(Number(this.state.tip)/Number(this.state.cart_details['total_amount']))*100
		if(tipPercentage==ctip)
		{
		  return "border-none badge-success ml-1"
	
		}else
		{
		  return "border-none badge-white myfont1 ml-1"
	
		}
	
	
	  };
	finishPayCollection =(mode="stripe")=>{
		let inputParams={}
		if(mode=="moneris")
		{
			console.log(this.props.key_name);
			inputParams['restaurant_name']=this.props.key_name;
			inputParams['order_id']=this.props.order_id;
			inputParams['moneris']=true
		console.log("TestInputParams");
		console.log(inputParams);
		}else{
		inputParams={...this.state.cart_details}
		}
		this.cartHandler.createPayCollection(inputParams).then((response) => {
			if(response.data.status=='sucess')
				{
					this.setState({
						pay_status:true,
						status:Paid
					})
				}
				else{
					this.setState({
						pay_status:true,
						status:Failed
					})
				}}).catch((err) => alert(err));
	}
	setCustomerParams=()=>{
		let inputParams={}
		inputParams['name']=this.props.key_name
		inputParams['order_id']=this.props.order_id
		this.dataHandler.getCartProfile(inputParams).then((response) => {
			if(response.data.status=='sucess')
			{
      
			let currentData = response.data.data;
			var cart=currentData.cart;
			var profile=currentData.profile;
			var newStatus=0
			if(this.state.status==100)
			{
				newStatus= (cart['pay_status']==false)?Payable:Expired
			}
			if(this.state.status==101)
			{
				newStatus= (cart['pay_status']==true)?Paid:Expired

			}
			this.setState({
				status:newStatus,
				cart_details:cart,
				pay_status:cart['pay_status'],
				profile:profile,
				
			},()=>{
				var tipAmount = this.getPercentage(10);
				this.setState({tip:tipAmount})
			})
			}
			else{
				var profile={}
				if(response.data.data){
				 profile = response.data.data.profile;
				}
				this.setState({
					status:Expired,
					profile:profile
				})
			}});
	}

    getPercentage=(perX)=>{
		var tip=(Number(this.state.cart_details['total_amount']*perX)/100);
		return tip
	}

		
	
	setMonerisPayment =(paymentToken) => {
		this.cartHandler.getCartParams(this.state.key_name);
		let inputForm = { ...this.state.cart_details };
		inputForm["payment_mode"] = "PAY ONLINE";
		inputForm['restaurant_name']=this.state.profile.title
		inputForm['payment_status'] = 1;  
		inputForm['total_amount']=(Number(inputForm['total_amount'])+Number(this.state.tip)).toFixed(2)
		inputForm['order_id']=this.state.cart_details.order_id
		inputForm["payment_details_stripe"]=paymentToken
		this.setState({ cart_details: inputForm },()=>{this.finishPayCollection();});
	
	  }

	setStripePayment =(paymentToken) => {
		let inputForm = { ...this.state.cart_details };
		inputForm["payment_mode"] = "PAY ONLINE";
		inputForm['restaurant_name']=this.state.profile.title
		inputForm['payment_status'] = 1;  
		inputForm['total_amount']=(Number(inputForm['total_amount'])+Number(this.state.tip)).toFixed(2)
		inputForm['order_id']=this.state.cart_details.order_id
		inputForm["payment_details_stripe"]=paymentToken
		this.setState({ cart_details: inputForm },()=>{this.finishPayCollection();});
	
	  }
	  


	 
	render() {
		if((this.state.pay_status==false)&&(this.state.status==Payable)){
		return (
			<div className="bg-white rounded shadow-sm p-4 mb-4 mt-3">
				<Row>
				<Col md={12} className={"item-center"}>
				<h6>{this.state.profile.restaurant_name}</h6>
				</Col>
				<Col md={12} className={"item-center"}>

			 
			  {typeof (this.state.profile.logo != "undefined") && (
                      <Image
                        fluid
                        className="mr-3 float-left"
                        alt=""
                        src={window.image_path + this.state.profile.logo}
                      />
                    )}
				</Col>
				<Col md={12} className={"item-center"}>
				<p>			  <b>{this.state.profile.restaurant_name}</b> is requesting online payment”. The payment will be securely processed by Moneris. This link will expire in 20 minutes
</p>
			  		

			

				</Col>

				</Row>
			 
			  
			  <Row>
				<Col md={12}>
				  <div className="form-row">
					<Form.Group className="col-md-12">
					  <Form.Label> Name</Form.Label>
				
					  <InputGroup>
						<Form.Control
						  name="first_name"
						  disabled={true}
						  defaultValue={this.state.cart_details["first_name"]}
						  type="text"
						  placeholder="First Name" className="addresslocat"
						/>
					  </InputGroup>
		
					</Form.Group>
					
					<Form.Group className="col-md-12">
							<Form.Label>Address</Form.Label>

											<InputGroup>
												<Form.Control type="text" name="address"    
                                            value={this.state.cart_details["address"]}
                                            disabled={true} className="addresslocat"
 placeholder="Address" />

											</InputGroup>
											</Form.Group>
											<Form.Group className="col-md-12">
					  <Form.Label>Payable Amount</Form.Label>
				
					  <InputGroup>
						<Form.Control
						  name="total_amount"
						  disabled={true}
						  defaultValue={this.state.cart_details["total_amount"]}
						  type="text"
						  placeholder="total_amount" className="addresslocat"
						/>
					  </InputGroup>
					  <Form.Label>Tip your Driver</Form.Label>
					  
	  
					  <InputGroup>
						<Form.Control
						  name="tip"
						  value={(this.state.tip).toFixed(2)}
						  onChange={(e)=>{
								if(e){
									
									this.setTip((e.target.value))
								}
						  }}
						  type="text"
						  placeholder="Last Name" className="addresslocat"
						/>
					  </InputGroup>
					</Form.Group>

					<div className="mb-2 bg-white rounded p-2 clearfix ">
                  <span className="float-right ml-2">
                  <Form.Label htmlFor="tip">Tip</Form.Label>
                  <button 
                  
                  className={this.findTipPercentage(0)             
                }
                  
                    onClick={() => {
						this.setTip(this.getPercentage(0))
        
                      //this.getTipInPercentage(5);
                    }}
                  >
                    {" "}
                    No Tip
                  </button>


                  <button 
                  
                  className={this.findTipPercentage(5)             
                }
                  
                    onClick={() => {
						
            
						this.setTip(this.getPercentage(5))
                   }}
                  >
                    {" "}
                    5%
                  </button>

                  <button className= {this.findTipPercentage(10)             
                }
                    
				onClick={() => {
						
            
					this.setTip(this.getPercentage(10))
				}}
                  >
                    {" "}
                    10%
                  </button>
                  <button className= {this.findTipPercentage(15)             
                }
                   
				onClick={() => {
						
            
					this.setTip(this.getPercentage(15))
				}}
                  >
                    {" "}
                    15%
                  </button>
				  <p></p>
				  {
					  ((!this.state.tip)||(this.state.tip==0))&&<Badge variant='primary'>{" "}100% of the tip goes to the driver</Badge>}

                 
                  </span> 

                </div>

				<Form.Group className="col-md-12">
              <Form.Label> Total Amount To be Paid : {(Number(this.state.cart_details['total_amount'])+Number(this.state.tip)).toFixed(2)}</Form.Label>

				</Form.Group>
               
					
					
					{
            (this.state.profile.online_payment_mode==
            ONLINE_PAYMENT_MODES['MONERIS_HOSTED_PAGE'])&&<Col md={12}>
            <Form.Group className="col-md-12">
              <Form.Label> </Form.Label>
			  <Moneris 
			storeId="ps_store_id"
			cust_id={"deliverycustomer"}
   			cart_details={this.state.cart_details}
			button_stat={((this.state.tipStatus===true)?"":"disabled")}

   			profile={this.state.profile}
			amount={(Number(this.state.cart_details['total_amount'])+Number(this.state.tip)).toFixed(2)}
			></Moneris>
            </Form.Group>
          </Col >}
         {          (this.state.profile.online_payment_mode==
            ONLINE_PAYMENT_MODES["STRIPE"])&&<Col md={12}>
          <Form.Group className="col-md-12">
              <Form.Label> </Form.Label>
          {<StripeCheckoutButton
          button_stat={(this.state.tipStatus===true)}
          price={(Number(this.state.cart_details['total_amount'])+Number(this.state.tip)).toFixed(2)}
          setStripePayment={this.setStripePayment}
          cust_id={"deliverycustomer"}
          login_details={{"first_name":this.state.cart_details['first_name'],
		  address:this.state.cart_details["address"],
		  email:"stripe_pay@test.com"
		}}
          profile={this.state.profile}
          ></StripeCheckoutButton>}</Form.Group>
          </Col>}
					
					
		  </div></Col>
					</Row>
					
					
					<Row>
					{(this.state.profile.online_payment_mode==
            ONLINE_PAYMENT_MODES['MONERIS_CHECKOUT_PAGE'])&& <Col md={12}>
		<Form.Group className="col-md-12">
              <Form.Label> </Form.Label>
                <button 
                  
                  className={"btn btn-primary btn-lg btn-block"}
                  
                    onClick={(e) => {
                      
                     this.getMonerisPage()
                      
                      
                      //this.getTipInPercentage(5);
                    }}
                  >
                    {" "}
                   Pay With Checkout
                  </button>
</Form.Group>
                </Col>}

        </Row>
					
					
					</div>)
	    }
		else if ((this.state.pay_status==true)&&(this.state.status==Paid))
		{
			return (	<div>
				<Row>
					<Col md={12} className={"item-center"}>
				<Alert>	
				"Your Payment has been successfully processed. {this.state.profile.restaurant_name} would like to Thank you for the payment. You can also place online order with us 
				<br></br>
				<a href={"/"+this.props.key_name}>Go Home</a>
				</Alert></Col></Row>
			</div>);
		}
		else if(this.state.status==Failed)
		{
			return (	<div>
				<Row>
					<Col md={12} className={"item-center"}>
			
				<p>
					Sorry ! Transaction failed.
				</p>
				</Col></Row>
			</div>);
		}
		else if(this.state.status==Expired){
			
			return (	<div>
					<Row>
					<Col md={12} className={"item-center"}>
				<br></br>
				<br></br>
				<br></br>

				<p>
				Expired Link.  <a href={"/"+this.state.profile.title}>Go Home</a>
				</p>
				<p>
				
				</p></Col></Row>
			</div>);
		}
		
		else{
			if(this.state.pay_status=="moneris")
			return (<MonerisCheckoutForm></MonerisCheckoutForm>)
			else{
			return (	<div>
					<Row>
					<Col md={12} className={"item-center"}>
			
				<p>
					....Loading
				</p> </Col></Row>
			</div>);
			}
		}
	}
}

class MonerisCheckoutForm extends React.Component {
	constructor(props, context) {
	  super(props, context);
	}
	
	render() {
	  return (<div className="bg-white rounded shadow-sm p-4 mb-4 mt-3">
  
	  { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize('<div id="monerisCheckout"></div>') }} /> }
	  </div>)
	}
  }

export const Moneris = (props) => {
	let rawHTML = '<FORM METHOD="POST" ACTION='+props.profile.payment_provided_url+'><INPUT TYPE="HIDDEN" NAME="ps_store_id" VALUE="'+props.profile.payment_app_id+
	'"><INPUT TYPE="HIDDEN" NAME="hpp_key" VALUE="'+
	props.profile.hpp_key+
	'">'+
	
	'<INPUT TYPE="HIDDEN" NAME="ship_first_name" VALUE="'+ props.profile.title+'"></INPUT>'+
	'<INPUT TYPE="HIDDEN" NAME="cust_id" VALUE="xxx-delivery-customer"></INPUT>'+
  
	'<INPUT TYPE="HIDDEN" NAME="ship_address_one" VALUE="'+ props.profile.address+'"></INPUT>'+
	'<INPUT TYPE="HIDDEN" NAME="bill_first_name" VALUE="'+ props.cart_details["first_name"]+'"></INPUT>'+
	'<INPUT TYPE="HIDDEN" NAME="bill_last_name" VALUE="'+ props.cart_details["last_name"]+'"></INPUT>'+
	'<INPUT TYPE="HIDDEN" NAME="bill_address_one" VALUE="'+ props.cart_details["address"]+'"></INPUT>'+
	'<INPUT TYPE="HIDDEN" NAME="delivery_only" VALUE="1"></INPUT>'+
	'<INPUT TYPE="HIDDEN" NAME="order_id" VALUE="'+ props.cart_details["order_id"]+'"></INPUT>'+
     '<INPUT TYPE="HIDDEN" NAME="charge_total" VALUE="'+props.amount+'">'+'<button type="SUBMIT" class="btn btn-primary btn-lg btn-block"'+ props.button_stat+ '>PAY ONLINE</button>'+
	'</FORM>';
  
   return( <div>
	  { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rawHTML) }} /> }
	</div>);
  }
export default DeliveryRequest;