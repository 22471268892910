import React from 'react';
import {NavLink,Link} from 'react-router-dom';
import {Navbar,Nav,Container,NavDropdown,Image,Badge, Row,
	Col} from 'react-bootstrap';
import DropDownTitle from '../common/DropDownTitle';
import CartDropdownHeader from '../cart/CartDropdownHeader';
import CartDropdownItem from '../cart/CartDropdownItem';
import Icofont from 'react-icofont';
import DataService from '../../services/DataService'
import CategoriesCarousel from "./CategoriesCarousel";


class Header extends React.Component {
	constructor(props) {
	    super(props);
		this.dataHandler=new DataService('restaurants');
		let cart=this.dataHandler.getCart(this.props.pathName,);



	    this.state = {
	      isNavExpanded: false,
		  key_name:this.props.pathName,
		  cart:cart,
	    };
	}

    setIsNavExpanded = (isNavExpanded) => {
      this.setState({ isNavExpanded: isNavExpanded });
    }
    closeMenu = () => {
      this.setState({ isNavExpanded: false });
    }

    handleClick = (e) => {
      if (this.node.contains(e.target)) {
        // if clicked inside menu do something
      } else {
        // If clicked outside menu, close the navbar.
        this.setState({ isNavExpanded: false });
      }
    }

	componentDidMount() {
	    document.addEventListener('click', this.handleClick, false);
	}

	componentWillUnmount() {
	    document.removeEventListener('click', this.handleClick, false);
	}
	render() {
    	return (
    		<div ref={node => this.node = node}>
				<section className="section pt-1 pb-3 text-center bg-white content-mobile-head top">
			         <Container>
						
			            <Row>
						
							<Col sm={12} className="bg-white">
							<Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to={"/"+this.props.pathName}>
							Home <span className="sr-only">(current)</span>
						</Nav.Link>
								</Col>
 
			              {(this.props.pathLength==2)&& <Col sm={12} className="bg-white">
						   <CategoriesCarousel 
						   
						   categories={this.props.displayCategories}

						   />
			               </Col>}
			            </Row>
			         </Container>
			    </section>
			<Navbar onToggle={this.setIsNavExpanded}
           expanded={this.state.isNavExpanded} color="light" expand='lg' className="navbar-light osahan-nav shadow-sm">
			   <Container>
			      {/*<Navbar.Brand to="/"><Image src="/img/1.jpg" width="100px" height="100px" alt='' /><span class="badge badge-danger">online</span></Navbar.Brand>*/}
			      
			      <Navbar.Collapse id="navbarNavDropdown">
			         <Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
						<Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to={"/"+this.props.pathName}>
							Home <span className="sr-only">(current)</span>
						</Nav.Link>
			         </Nav>
			      </Navbar.Collapse>
			   </Container>
			</Navbar>
			</div>
		);
	}
}

export default Header;