import React from 'react';
import OwlCarousel from 'react-owl-carousel3';
import ProductBox from '../home/ProductBox';
import {baseURL} from '../../helpers/globalConstants';
class CategoriesCarousel extends React.Component {

	render() {
    	return (
		
	      <OwlCarousel nav loop {...options} className="owl-carousel-category owl-theme " margin={10}>
			    { (typeof( this.props.categories)!='undefined')&&
									   
									   this.props.categories.map((catObj,idx)=>
									   {
									let	image='img/list/1.png';
									image=(catObj.image)?baseURL+catObj.image:image;
									

										 return (
	         <div className="item">
	         	<ProductBox 
	         		boxClass='osahan-category-item'
	         		title=
					 {catObj.title}
					 id={catObj.id}

			   		image={image}
			   		imageClass='img-fluid'
			   		imageAlt='carousel'
			   		linkUrl={'#'+"catList"+catObj.id}
					idx={idx}
			   	/>
	         </div>)})}
	        
	      </OwlCarousel>
	    );
	}
}

const options = {
	responsive: {
	  0: {
		items: 2,
	  },
	  764: {
		items: 2,
	  },
	  765: {
		items: 1,
	  },
	  1200: {
		items: 1,
	  },
	},
	autoWidth: true,
	startPosition: 1,
	screenLeft:true,
	lazyLoad: true,
	loop: true,
	autoplay: false,
	autoplaySpeed: 2000,
	dots: false,
	autoplayTimeout: 2000,
	nav: true,
	navText: [
	  "<i class='fa fa-chevron-left'></i>",
	  "<i class='fa fa-chevron-right'></i>",
	],
	autoplayHoverPause: true,
  };

export default CategoriesCarousel;