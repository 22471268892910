import React from 'react';
import OwlCarousel from 'react-owl-carousel3';
import MayLikeItem from './MayLikeItem';
import { baseURL,ima } from  "../../helpers/globalConstants";

class ItemsCarousel extends React.Component {

	render() {
    	return (
	      <OwlCarousel nav loop {...options} className="owl-theme owl-carousel-five offers-interested-carousel">
			  {this.props.recomandedItems.map((itemObj)=>(
	         <div className="item">
	         	<MayLikeItem 
	         		title={itemObj.title}
	         		price={itemObj.price}
			   		image={window.image_path + itemObj.image}
			   		imageClass='img-fluid'
			   		imageAlt='carousel'
					onClickUpCellItem={() =>
						this.props.onClickUpCellItem(itemObj)
					  }
 
					   show={this.props.show}
					   onHide={this.props.onHide}
					   item={this.props.itemSelected}
					   setCustomaize={this.props.setCustomaize}
					/>
	         </div>))}
	       
	      </OwlCarousel>
	    );
	}
}

const options={
	responsive: {
        0:{
            items:2,
        },
        600:{
            items:3,
        },
        1000: {
          items: 4,
        },
        1200: {
          items: 5,
        },
      },
    lazyLoad: true,
    pagination: "false",
    loop: false,
    dots: false,
    autoPlay: 2000,
    nav: true,
    navText: ["<i class='icofont-thin-left'></i>", "<i class='icofont-thin-right'></i>"]
}

export default ItemsCarousel;