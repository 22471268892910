// stripe.button.component.jsx
import React from 'react';
import { Button } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import StripeCheckout from 'react-stripe-checkout';

const StripeCheckoutButton = (props) => {
    const priceForStripe = props.price * 100;
    const onToken = token => {
        console.log(token);
       props.setStripePayment(token.id);
    };

    return (
        <StripeCheckout
            label='Pay with Stripe'
            name={props.login_details["first_name"]}
            email={(props.login_details["email"])?(props.login_details["email"]):""}
            billingAddress={false}
            enabled={props.button_stat}
            ComponentClass={"StripeCheckout"}
            
            color ={'ffff'}
            shippingAddress={false}
            image={window.image_path + props.profile.logo}
            description={`Your total is $${props.price}`}
            amount={priceForStripe}
            panelLabel='Pay with Stripe'
            token={onToken}
            stripeKey={props.profile.stripe_key}
        >
 <button className="btn btn-primary btn-lg btn-block" disabled={!props.button_stat}>
    PAY ONLINE
  </button>

        </StripeCheckout>
    )
}

export default StripeCheckoutButton;