import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Row,Col,Container} from 'react-bootstrap';
import FontAwesome from './FontAwesome';
import DataService from '../../services/DataService'
import { HashLink as HashLink } from 'react-router-hash-link';
import { VERSION } from './constants';
class Footer extends React.Component {
	constructor(props) {
	    super(props);
		this.dataHandler=new DataService('restaurants');
		let car=this.dataHandler.getCart(this.props.pathName);
	    this.state = {
	      isNavExpanded: false,
		  key_name:this.props.pathName,
		  cart_length:0
	    };
	}	


	componentDidMount() {
		var index = 0;
		this.timer = setInterval(() => {
		  this.setState({ cart_length: this.dataHandler.getCart(this.props.pathName).length});
		console.log('called');
		}, 1000)
	  }
	
	  componentWillUnmounnt() {
		clearInterval(this.timer);
	  }
	

	render() {
    	return (
    		<>
			  {(this.props.disable_view_cart==false)&& (this.state.cart_length>0) && <section className="section pt-1 pb-3 text-center bg-white content-mobile fixed-content">
			         <Container>
			            <Row>
						<Col sm={8} >
							</Col>
							{/*<Col sm={8} >
								
								 <p  className="mb-3 text-success font-weight-bold applycoupon">applied coupen dummy worth <span className="text-danger "> dummy $</span></p>
								 
								 
		</Col>*/}
			               <Col sm={4}>
						   <HashLink to="#yourAnchorTag" className="btn btn-success btn-block btn-sm">View Cart({
this.state.cart_length

						   })</HashLink>
			               </Col>
			            </Row>
			         </Container>
			    </section>}


		      <footer className="pt-4 pb-4 text-center mb-4">
		         <Container>
		            <p className="mt-0 mb-0">{this.props.copyrightText}</p>
		            <small className="mt-0 mb-0"> Made with <FontAwesome icon={this.props.madewithIconclassName} /> by
		            <Link className="text-danger ml-1" target="_blank" to={this.props.secondLink}>{this.props.firstLinkText}</Link>
					- {VERSION}
		            </small>
		         </Container>
		      </footer>
		    </>
    	);
    }
}


Footer.propTypes = {
  sectionclassName: PropTypes.string,
  popularCHclassName:PropTypes.string,
  popularCountries: PropTypes.array,
  popularFHclassName:PropTypes.string,
  popularFood: PropTypes.array,
  copyrightText: PropTypes.string,
  madewithIconclassName: PropTypes.string,
  firstLinkText: PropTypes.string,
  firstLink: PropTypes.string,
  secondLinkText: PropTypes.string,
  secondLink: PropTypes.string,
};

Footer.defaultProps = {
    sectionclassName: 'footer-bottom-search pt-5 pb-5 bg-white',
	popularCHclassName:'text-black',
	popularCountries: [],
	popularFHclassName:'mt-4 text-black',
	popularFood: [],
	copyrightText: '© Copyright 2024 iOrders. All Rights Reserved',
	madewithIconclassName: 'heart heart-icon text-danger',
	firstLinkText: 'iOrders',
	secondLinkText: 'iOrders',
	secondLink: '//iorders.ca/',
}



export default Footer;