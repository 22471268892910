import React, { useState } from 'react';
import{
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Collapse,
  } from "reactstrap";

function DataCollapse(props) {
    const [col1, setcol1] = useState(true)
    const [col2, setcol2] = useState(true)
    const [col3, setcol3] = useState(true)
    return (
        <div>
<Row>
<Col xl={12}>
    <Card>
      <CardBody>
        <CardTitle className="h4">Accordion example</CardTitle>
        <p className="card-title-desc">
           
          Extend the default collapse behavior to create an accordion.
        </p>
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button"
                onClick={() => {
                  setcol1(!col1)
                }}
                style={{ cursor: "pointer" }}>
                Accordion Item #1
                                      </button>
            </h2>
            <Collapse id="collapseOne" className="accordion-collapse show"
              isOpen={col1}>
              <div className="accordion-body">
            
                <strong>This is the first item's accordion body.</strong> It is hidden
                                          by default, until the collapse plugin adds the appropriate classes that
                                          we use to style each element. These classes control the overall
                                          appearance, as well as the showing and hiding via CSS transitions. You
                                          can modify any of this with custom CSS or overriding our default
                                          variables. It's also worth noting that just about any HTML can go within
                                          the <code>.accordion-body</code>, though the transition does limit
                                          overflow.
                                      </div>
            </Collapse>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button"
                onClick={() => {
                  setcol2(!col2)
                }}
                style={{ cursor: "pointer" }}>
                Accordion Item #2
                                      </button>
            </h2>
            <Collapse id="collapseTwo" className="accordion-collapse" isOpen={col2}>
              <div className="accordion-body">
                <strong>This is the second item's accordion body.</strong> It is hidden
                                          by default, until the collapse plugin adds the appropriate classes that
                                          we use to style each element. These classes control the overall
                                          appearance, as well as the showing and hiding via CSS transitions. You
                                          can modify any of this with custom CSS or overriding our default
                                          variables. It's also worth noting that just about any HTML can go within
                                          the <code>.accordion-body</code>, though the transition does limit
                                          overflow.
                                      </div>
            </Collapse>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button className="accordion-button collapsed" type="button"
                onClick={() => {
                  setcol3(!col3)
                }}
                style={{ cursor: "pointer" }}>
                Accordion Item #3
                                      </button>
            </h2>
            <Collapse id="collapseThree" className="accordion-collapse" isOpen={col3}>
              <div className="accordion-body">
                <strong>This is the third item's accordion body.</strong> It is hidden
                                          by default, until the collapse plugin adds the appropriate classes that
                                          we use to style each element. These classes control the overall
                                          appearance, as well as the showing and hiding via CSS transitions. You
                                          can modify any of this with custom CSS or overriding our default
                                          variables. It's also worth noting that just about any HTML can go within
                                          the <code>.accordion-body</code>, though the transition does limit
                                          overflow.
                                      </div>
            </Collapse>
          </div>
        </div>
      
      </CardBody>
    </Card>
  </Col>
</Row>
</div>)
}
export default DataCollapse;