import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { alertError } from '../components/Alerts';
import DOMPurify from 'dompurify';


class MonerisPayPage extends React.Component {
	constructor(props) {

		super(props);
			this.state = {
			


				
		}

	}
	componentDidMount() 
	{
	let ticket = Buffer.from(this.props.ticket,"base64").toString("utf8");
	let name=this.props.key_name
	window.loadMOneris(ticket)
	}
    
		
		  



	render() {
		let phone = "";
		let prep_time = "";
		let address = "";
		let prepMessage = "";
		let delMessage = "";
	
		return (
			<section className="section pt-5 pb-5 osahan-not-found-page">
				<Container>
					
					<MonerisForm></MonerisForm>
						
				</Container>
			</section>
		);
	}
}

class MonerisForm extends React.Component {
	constructor(props, context) {
	  super(props, context);
	}
	
	render() {
	  return (<div>
   
	  { <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize('<div id="monerisCheckout"></div>') }} /> }
	  </div>)
	}
  }
  
export default MonerisPayPage;