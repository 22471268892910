import React from "react";
import {
	Form,
	Row,
	Col,
	InputGroup,
	Modal,
	FormGroup,
	Label,
	ButtonToolbar,
	Button,
	ToggleButton,
	ToggleButtonGroup,
	Image,
	Media,
} from "react-bootstrap";
import Icofont from "react-icofont";
import { formatSpace } from "../../services/DataFormatHandler";

class FreeOfferItemsSelectionModal extends React.Component {
	constructor(props, context) {
		super(props, context);

		let data = this.getUpdatedOffer();
		this.state = {
			offer: data.offer,
			totalOfferItemSelected: data.totalOfferItemSelected,
			offerItemSelectionLimitReached: data.offerItemSelectionLimitReached,
		};
		// this.getUpdatedOffer();
	}

	componentDidUpdate(prevProps) {
		if (this.props.cart !== prevProps.cart) {
			let data = this.getUpdatedOffer();
			this.setState({
				offer: data.offer,
				totalOfferItemSelected: data.totalOfferItemSelected,
				offerItemSelectionLimitReached: data.offerItemSelectionLimitReached,
			});
		}
	}

	getUpdatedOffer = () => {
		const selectedOfferFoodItemsInCart = this.props.cart
			.map((item, index) => ({ ...item, cartPosition: index }))
			.filter((item) => item.appliedOfferId === this.props.selectedOffer.id);

		console.log(selectedOfferFoodItemsInCart);

		let offer = { ...this.props.selectedOffer };
		offer.offered_items = [];

		let totalOfferItemSelected = 0;

		this.props.selectedOffer.offered_items.forEach((item) => {
			// assuming offer item only exist once in cart without duplicate id rather than quantity
			let foundItem = selectedOfferFoodItemsInCart.find(
				(a) => a.id === item.item
			);
			let selectedQuantity = foundItem ? foundItem.quantity : 0;
			let cartPosition = foundItem ? foundItem.cartPosition : null;
			let image = "img/empty_image.jpeg";
			if (item.image) {
				image = window.image_path + item.image;
			} else if (this.props.profile.default_food_item_image) {
				image =
					window.image_path + this.props.profile.default_food_item_image;
			}

			offer.offered_items.push({
				...item,
				title: item.item_name,
				id: item.item,
				image: image,
				appliedOfferId: offer.id,
				quantity: selectedQuantity,
				upCellItems: [],
				customAnswers: [],
				cartPosition,
			});
			totalOfferItemSelected += selectedQuantity;
		});
		console.log(offer.offered_items);
		return {
			offer: offer,
			totalOfferItemSelected: totalOfferItemSelected,
			offerItemSelectionLimitReached:
				totalOfferItemSelected ===
				offer.max_number_of_items_can_be_selected,
		};
	};

	render() {
		return (
			<Modal
				// className={"zx"}
				show={this.props.show}
				onHide={this.props.onHide}
				centered
				size="lg"
			>
				<Modal.Header
					closeButton={false}
					style={{ justifyContent: "center" }}
				>
					<Modal.Title as="h7" id="">
						{`${this.state.totalOfferItemSelected} Out Of ${this.state.offer.max_number_of_items_can_be_selected} Free Items Selected`}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body
					style={{
						height: "60vh",
						overflowY: "scroll",
						overflowX: "hidden",
					}}
				>
					{this.state.offer && (
						<div>
							{this.state.offer.offered_items.map((item, idx) => (
								<div className={"p-3 border-bottom menu-list"}>
									<Row>
										<Col md={6}>
											<Media>
												<Image
													className={"mr-3 rounded-pill "}
													src={item.image}
													alt={""}
												/>
												<Media.Body>
													<h5 className="mb-1">
														{item.title}
													</h5>
													<p className="text-gray mb-0">
														{"$"}
														{item.price}
													</p>
												</Media.Body>
											</Media>
										</Col>
										<Col md={4}>
											<span className="count-number float-right">
												<Button
													disabled={item.quantity <= 0}
													variant="outline-secondary"
													onClick={() =>
														this.props.decreaseItem(
															item,
															item.cartPosition
														)
													}
													className="btn-sm left dec"
												>
													{" "}
													<Icofont icon="minus" />{" "}
												</Button>
												<input
													className="count-number-input"
													type="text"
													value={
														item.quantity +
														formatSpace(
															3,
															item.quantity
														)
													}
													readOnly
												/>
												<Button
													disabled={
														this.state
															.offerItemSelectionLimitReached
													}
													variant="outline-secondary"
													onClick={() => {
														if (item.quantity > 0) {
															this.props.incrementItem(
																item,
																item.cartPosition
															);
														} else {
															this.props.addCartItem({
																id: item.id,
																discount:
																	item.discount,
																title: item.title,
																price: item.price,
																is_delivery_enabled:
																	item.is_delivery_enabled,
																is_takeout_enabled:
																	item.is_takeout_enabled,
																quantity: 1,
																item_suggestion: "",
																upCellItems: [],
																customAnswers: [],
																appliedOfferId:
																	item.appliedOfferId,
																isFree:
																	item.free_available ??
																	false,
															});
														}
													}}
													className="btn-sm right inc"
												>
													{" "}
													<Icofont icon="icofont-plus" />{" "}
												</Button>
											</span>
										</Col>
										<Col md={2}>
											<p className="text-gray mb-0 float-right ml-2">
												{"$"}
												{(
													+item.price * item.quantity
												).toFixed(2)}
												{formatSpace(
													6,
													item.price * item.quantity,
													" ",
													false
												)}
											</p>
										</Col>
									</Row>
								</div>
							))}
						</div>
					)}
				</Modal.Body>
				<Modal.Footer
					style={{
						alignItems: "center",
						alignSelf: "center",
						width: "100%",
						justifyContent: "center",
					}}
				>
					{!this.props.skipOfferSelection && (
						<Button onClick={this.props.onSkipOfferSelection}>
							skip
						</Button>
					)}
					<Button
						disabled={this.state.totalOfferItemSelected <= 0}
						onClick={this.props.onHide}
					>
						Ok
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default FreeOfferItemsSelectionModal;
