//const baseURL='http://www.abaint.in/';
const baseURL = window.api_end;
const currentURL = window.current_url;
//const currentURL="http://baant.in/";

const days = [
	"SUNDAY",
	"MONDAY",
	"TUESDAY",
	"WEDNESDAY",
	"THURSDAY",
	"FRIDAY",
	"SATURDAY",
];
const timeZoneList = [
	"Asia/Calcutta", // Indian time zone
	"Canada/Atlantic",
	"Canada/Central",
	"Canada/Eastern",
	"Canada/Mountain",
	"Canada/Newfoundland",
	"Canada/Pacific",
	"Canada/Saskatchewan",
	"Canada/Yukon",
	"US/Alaska",
	"US/Aleutian",
	"US/Arizona",
	"US/Central",
	"US/East-Indiana",
	"US/Eastern",
	"US/Hawaii",
	"US/Indiana-Starke",
	"US/Michigan",
	"US/Mountain",
	"US/Pacific",
	"US/Samoa",
];
const timeZoneDifference = {
	"Asia/Calcutta": 5.5,
	"Canada/Atlantic": -4,
	"Canada/Central": -5,
	"Canada/Eastern": -5,
	"America/New_York": -5,
	"America/Chicago": -6,
	"America/Guatemala": -6,
	"Canada/Mountain": -7,
	"Canada/Newfoundland": -3.5,
	"Canada/Pacific": -8,
	"Canada/Saskatchewan": -6,
	"Canada/Yukon": -7,
	"US/Alaska": -9,
	"US/Aleutian": -10,
	"US/Arizona": -7,
	"US/Central": -6,
	"US/East-Indiana": -5,
	"US/Eastern": -5,
	"US/Hawaii": -10,
	"US/Indiana-Starke": -5,
	"US/Michigan": -5,
	"US/Mountain": -7,
	"US/Pacific": -8,
	"US/Samoa": -11,
};
const countries = ["Canada", "US"];
const defaultHeader = {
	"content-type": "application/json",
};
const accessHeader = {
	"content-type": "application/json",
	"Access-Control-Allow-Origin": "*",
	"Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
	"Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
};
const formHeader = {
	"content-type":
		"multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
};
const ONLINE_PAYMENT_MODES = {
	STRIPE: 1,
	MONERIS_HOSTED_PAGE: 2,
	MONERIS_CHECKOUT_PAGE: 3,
};
const timeZoneMap = {
	"Asia/Calcutta": "Asia/Kolkata",
	"Canada/Atlantic": "America/Halifax",
	"Canada/Central": "America/Winnipeg",
	"Canada/Eastern": "America/Toronto",
	"America/New_York": "America/New_York",
	"America/Chicago": "America/Chicago",
	"America/Guatemala": "America/Guatemala",
	"Canada/Mountain": "America/Edmonton",
	"Canada/Newfoundland": "America/St_Johns",
	"Canada/Pacific": "America/Vancouver",
	"Canada/Saskatchewan": "America/Regina",
	"Canada/Yukon": "America/Whitehorse",
};
export {
	baseURL,
	ONLINE_PAYMENT_MODES,
	currentURL,
	accessHeader,
	// timeZoneDifference,
	timeZoneList,
	defaultHeader,
	formHeader,
	// timeZoneMap,
};
