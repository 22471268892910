import Moment from "moment";
import moment from "moment-timezone";
// import { timeZoneDifference, timeZoneMap } from "../helpers/globalConstants";
const toFullDate = (dateObj) => {
	Moment.locale("en");
	return Moment(dateObj).format("dddd, MMMM Do YYYY, h:mm:ss a");
};
const isInBetween = (start_time, close_time) => {
	let startTime = Moment(start_time, "HH:mm:ss");
	let endTime = Moment(close_time, "HH:mm:ss");
	let currenTime = Moment();
	return currenTime.isBetween(startTime, endTime);
};
const isTimeBetween = (start_time, close_time, current_time) => {
	let startTime = Moment(start_time, "HH:mm:ss");

	let endTime = Moment(close_time, "HH:mm:ss");

	let currenTime = Moment(current_time, "hh:mm A");

	return currenTime.isBetween(startTime, endTime);
};
const getRestaurantTimeSlot = (t) => {
	let timeObj = "";
	if (t == false) {
		timeObj = Moment().utc().format("YYYY-MM-DD HH:mm:ss");
	} else {
		timeObj = Moment(t, "YYYY-MM-DD HH:mm:ss")
			.utc()
			.format("YYYY-MM-DD HH:mm:ss");
	}
	return timeObj;
};

const rad = (x) => {
	return (x * Math.PI) / 180;
};

const getDistance = (p1, p2) => {
	var R = 6378137; // Earth’s mean radius in meter
	var dLat = rad(p2.lat - p1.lat);
	var dLong = rad(p2.lng - p1.lng);
	var a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(rad(p1.lat)) *
			Math.cos(rad(p2.lat)) *
			Math.sin(dLong / 2) *
			Math.sin(dLong / 2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	var d = R * c;
	return d / 1000; // returns the distance in meter
};

const getCurrentSlot = () => {
	return {
		day: Moment().format("YYYY-MM-DD"),
		time: Moment().format("HH:mm:ss"),
	};
};

const timeCall = {
	SUNDAY: 1,
	MONDAY: 2,
	TUESDAY: 3,
	WEDNESDAY: 4,
	THURSDAY: 5,
	FRIDAY: 6,
	SATURDAY: 7,
};
const findDateFromDay = (dayString) => {
	let dayDigit = timeCall[dayString] - 1;
	let dateDay = nextDay(dayDigit);
	return dateDay;
};
const nextDay = (x) => {
	let now = new Date();
	now.setDate(now.getDate() + ((x + (7 - now.getDay())) % 7));
	let dayDate = Moment(now).format("YYYY-MM-DD");
	return dayDate;
};
const getDayIndex = (key) => {
	return timeCall[key];
};
const getTodayString = () => {
	let day = Moment().format("YYYY-MM-DD");
	return getDay(day);
};
const getDay = (mydate) => {
	var weekDayName = Moment(mydate).format("dddd");
	return weekDayName.toUpperCase();
};
const dayArrayToDic = (dayDic) => {
	let slotDic = [];
	dayDic.map((slotObj) => {
		if (!slotDic[slotObj["day"]]) {
			slotDic[slotObj["day"]] = [];
		}
		slotDic[slotObj["day"]].push(slotObj);
	});

	slotDic = slotDic.sort(function (a, b) {
		return getDayIndex(a["day"]) - getDayIndex(b["day"]);
	});
	return slotDic;
};
const toFormattedDate24 = (timeObj) => {
	let formatedDay = Moment("2015-01-01")
		.startOf("day")
		.seconds(timeObj)
		.format("H:mm:ss");
	return formatedDay;
};

const toTime24 = (timeObj) => {
	let formatedDay = Moment(timeObj, "h:mm A").format("H:mm:ss");
	return formatedDay;
};

// const toClientTime = (timeZone, timeObj) => {
// 	let time1 = Moment(timeObj, "YYYY-MM-DD h:mm A");

// 	let differenceInMinutes = timeZoneDifference[timeZone] * 60;

// 	let time2 = time1.subtract(differenceInMinutes, "minutes"); //now in utc
// 	let d = new Date();
// 	let clientTimeZone = moment.tz.guess();
// 	let n = new Date().getTimezoneOffset(); //d.getTimezoneOffset();
// 	let clientTime = time2.subtract(n, "minutes");
// 	let format = Moment(clientTime);

// 	return format;
// };

const toClientTime = (timeZone, timeObj) => {
	const sourceTimeZone = timeZone;
	if (!sourceTimeZone) {
		throw new Error(`Unknown time zone: ${timeZone}`);
	}

	// Parse the timeObj in the source time zone
	let timeInSourceTZ = moment.tz(timeObj, "YYYY-MM-DD h:mm A", sourceTimeZone);

	// Convert to UTC
	let timeInUTC = timeInSourceTZ.clone().utc();

	// Get client's time zone
	let clientTimeZone = moment.tz.guess();

	// Convert UTC to client's time zone
	let clientTime = timeInUTC.clone().tz(clientTimeZone);

	return clientTime;
};

const toCurrentTime = (timeObj) => {
	let time1 = Moment(timeObj, "HH:mm:ss");
	let d = new Date();
	let n = d.getTimezoneOffset();
	let subtract = time1.subtract(n);
	let format = Moment(subtract).format("hh:mm A");
	return format;
};

const isNow = (dateObj) => {
	let currentDate = Moment().format("DD/M/YYYY");
	let givenDate = Moment(dateObj).format("DD/M/YYYY");
	return givenDate === currentDate;
};
const formatSpace = (length, inputString, space = " ", tail = false) => {
	let spaceString = "";
	let number = Math.abs(length - ("" + inputString).length);
	spaceString = space.repeat(number);
	return spaceString;
};
const isAgo = (dateObj, days) => {
	var today = new Date();
	var day = new Date();
	day.setDate(today.getDate() - days);
	let currentDate = Moment(day).format("DD/M/YYYY");
	let givenDate = Moment(dateObj).format("DD/M/YYYY");
	return givenDate === currentDate;
};
const getCurrentDate = (formatString = "DD/M/YYYY") => {
	let currentDate = Moment().format(formatString);
	return currentDate;
};
const getDateAfter = (delta, formatString = "DD/M/YYYY") => {
	let currentDate = Moment().add(delta, "days").format(formatString);
	return currentDate;
};

const convertDate = (dateObj) => {
	let givenDate = Moment(dateObj).format("YYYY-MM-DD");
	return givenDate;
};
export {
	toFullDate,
	toCurrentTime,
	isNow,
	isInBetween,
	isAgo,
	dayArrayToDic,
	toFormattedDate24,
	toTime24,
	findDateFromDay,
	toClientTime,
	getCurrentDate,
	getDay,
	getTodayString,
	convertDate,
	getDistance,
	getCurrentSlot,
	getRestaurantTimeSlot,
	isTimeBetween,
	getDateAfter,
	formatSpace,
};
