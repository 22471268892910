import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Row, Col } from "react-bootstrap";
import Icofont from "react-icofont";
import { formatSpace } from "../../services/DataFormatHandler";

class CheckoutItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			quantity: this.props.quantity || 1,
			show: this.props.show || true,
			max: this.props.maxValue || 5,
			min: this.props.minValue || 0,
		};
	}
	incrementItem = (e) => {
		e.preventDefault();
		this.props.incrementItem(this.props.item, this.props.cartIndex);
	};
	decreaseItem = (e) => {
		e.preventDefault();
		this.props.decreaseItem(this.props.item, this.props.cartIndex);
	};
	toggleClick = () => {
		this.setState({ show: !this.state.show });
	};

	render() {
		return (
			<div className="gold-members p-2 border-bottom">
				<Row>
					<Col md={6}>
						<div className="media">
							<div className="media-body">
								<p className="mt-1 mb-0 text-black">
									{typeof this.props.item.title != "undefined" &&
										this.props.item.title}
									{typeof this.props.item.title != "undefined" &&
										formatSpace(15, this.props.item.title)}
								</p>

								<p className="mt-1 mb-0 smallitem">
									{this.props.item.upCellItems.length > 0 &&
										this.props.item.upCellItems.reduce(
											function (previous, current) {
												return (
													previous +
													(current.item_name +
														"X" +
														"(" +
														current.quantity +
														"X" +
														current.item_price +
														")" +
														" ")
												);
											},
											""
										)}
									{this.props.item.customAnswers.length > 0 &&
										this.props.item.customAnswers.reduce(
											function (previous, current) {
												return (
													previous +
													(current.answer_title +
														"(" +
														current.item_price +
														")" +
														" ")
												);
											},
											""
										)}
									{this.props.item.combo_sub_items &&
										this.props.item.combo_sub_items.map(
											(comboSubItem) => (
												<div
													style={{
														marginLeft: 10,
														color: "grey",
													}}
												>
													<p
														className="mt-1 mb-0 text-black "
														style={{ fontSize: 12 }}
													>
														{comboSubItem.quantity}{" "}
														{" x "}
														{typeof comboSubItem.title !=
															"undefined" &&
															comboSubItem.title}
														{comboSubItem.upCellItems
															.length > 0 ||
														comboSubItem.customAnswers
															.length > 0
															? " | "
															: ""}
														<span
															style={{
																color: "grey",
															}}
														>
															{comboSubItem
																.upCellItems
																.length > 0 &&
																comboSubItem.upCellItems.reduce(
																	function (
																		previous,
																		current
																	) {
																		return (
																			previous +
																			(current.item_name +
																				"X" +
																				"(" +
																				current.quantity +
																				"X" +
																				current.item_price +
																				")" +
																				" ")
																		);
																	},
																	""
																)}
															{comboSubItem
																.customAnswers
																.length > 0 &&
																comboSubItem.customAnswers.reduce(
																	function (
																		previous,
																		current
																	) {
																		return (
																			previous +
																			(current.answer_title +
																				"(" +
																				current.item_price +
																				")" +
																				" ")
																		);
																	},
																	""
																)}
														</span>
													</p>
												</div>
											)
										)}
								</p>
							</div>
							{/*<div className="mr-2"><Icofont icon="ui-delete" className="text-danger" /></div>*/}
						</div>
					</Col>
					<Col md={4}>
						{!this.props.item.isFree && (
							<span className="count-number float-right">
								<Button
									variant="outline-secondary"
									onClick={this.decreaseItem}
									className="btn-sm left dec"
								>
									{" "}
									<Icofont icon="minus" />{" "}
								</Button>
								<input
									className="count-number-input"
									type="text"
									value={
										this.props.item.quantity +
										formatSpace(3, this.props.item.quantity)
									}
									readOnly
								/>
								<Button
									variant="outline-secondary"
									onClick={this.incrementItem}
									className="btn-sm right inc"
								>
									{" "}
									<Icofont icon="icofont-plus" />{" "}
								</Button>
							</span>
						)}
					</Col>
					<Col md={2}>
						{!this.props.item.isFree && (
							<p className="text-gray mb-0 float-right ml-2">
								{this.props.priceUnit}
								{(
									+this.props.item.price *
									this.props.item.quantity
								).toFixed(2)}
								{formatSpace(
									6,
									this.props.item.price *
										this.props.item.quantity,
									" ",
									false
								)}
							</p>
						)}
						{this.props.item.isFree && (
							<p className="text-gray mb-0 float-right ml-2">FREE</p>
						)}
					</Col>
				</Row>
			</div>
		);
	}
}

CheckoutItem.propTypes = {
	title: PropTypes.string.isRequired,
	price: PropTypes.number.isRequired,
	priceUnit: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	quantity: PropTypes.number.isRequired,
	show: PropTypes.bool.isRequired,
	minValue: PropTypes.number,
	maxValue: PropTypes.number,
	getValue: PropTypes.func.isRequired,
};
CheckoutItem.defaultProps = {
	show: true,
	priceUnit: "$",
};

export default CheckoutItem;
