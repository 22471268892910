import React from "react";
import { Row, Col, Image, Badge, Button, Media, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";

class QuickBite extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			quantity: this.props.item.quantity || 0,
			show: this.props.show || true,
			max: this.props.maxValue || 5,
			min: this.props.minValue || 0,
		};
	}

	truncateParagraph = (paragraph, characterLimit) => {
		if (paragraph.length > characterLimit) {
			return paragraph.substring(0, characterLimit) + "...";
		}
		return paragraph;
	};

	IncrementItem = () => {
		this.props.incrementItem(this.props.item);
	};
	DecreaseItem = () => {
		this.props.decreaseItem(this.props.item);
	};

	render() {
		return (
			<Card
				className="mb-3"
				style={{
					borderRadius: "15px",
					boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
					transition: "transform 0.2s",
					position: "relative",
				}}
				onMouseEnter={(e) =>
					(e.currentTarget.style.transform = "scale(1.02)")
				}
				onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
			>
				<div
					className="row no-gutters"
					style={{ flexWrap: "nowrap", height: "130px" }}
				>
					<div className="col-md-3" style={{ width: "39%" }}>
						<Card.Img
							src={this.props.image}
							className="h-100 rounded-left"
							style={{ objectFit: "cover" }}
						/>
						{/* {this.props.showBadge && (
							<div
								style={{
									position: "absolute",
									bottom: "10px",
									left: "10px",
									backgroundColor: "#9bb341",
									color: "white",
									padding: "5px 10px",
									borderRadius: "5px",
									fontSize: "0.8rem",
								}}
							>
								
								{this.props.discount}% offer
								
							</div>
						)} */}
					</div>
					<div className="col-md-9">
						<Card.Body
							className={
								this.props.description
									? "d-flex flex-column justify-content-center h-100"
									: "d-flex flex-column justify-content-between h-100"
							}
						>
							<div>
								<Card.Title
									style={{
										fontSize: "16px",
										marginBottom: "5px",
									}}
								>
									<h5
										style={{
											marginBottom: 5,
											display: "flex",
											alignItems: "center",
										}}
									>
										{this.props.title}
										{this.props.subTitle &&
											this.props.subTitle
												.split(",")
												.map((tag) => {
													if (
														tag &&
														tag != null &&
														tag != "null"
													) {
														return (
															<Link
																className=" text-info"
																to="#"
															>
																<Icofont icon="check-circled" />{" "}
																{" " + tag + " "}
															</Link>
														);
													}
												})}
										{this.props.item.is_gluten_free && (
											<Image
												src="/img/gluten_free.png"
												style={{
													width: "20px",
													height: "20px",
													marginLeft: "4px",
												}}
												roundedCircle
											/>
										)}
										{this.props.item.is_vegan && (
											<Image
												src="/img/vegan.png"
												style={{
													width: "20px",
													height: "20px",
													marginLeft: "4px",
												}}
												roundedCircle
											/>
										)}
										{this.props.item.is_vegetarian && (
											<Image
												src="/img/vegetarian.png"
												style={{
													width: "20px",
													height: "20px",
													marginLeft: "4px",
												}}
												roundedCircle
											/>
										)}
									</h5>
								</Card.Title>
								<Card.Text
									style={{
										fontSize: "0.75rem",
										color: "#555",
										// overflow: "hidden",
										// textOverflow: "ellipsis",
										// display: "-webkit-box",
										// WebkitLineClamp: 3,
										// WebkitBoxOrient: "vertical",
										// paddingBottom: 5,
										textAlign: "justify",
									}}
								>
									{this.props.description
										? this.truncateParagraph(
												this.props.description,
												90
										  )
										: ""}
								</Card.Text>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									marginTop: 2,
									alignItems: "center",
								}}
							>
								<span
									style={{
										fontSize: "0.9rem",
										// fontWeight: "bold",
										color: "black", // Theme color
									}}
								>
									{this.props.priceUnit}
									{this.props.price}
								</span>
								{this.props.showBadge ? (
									<h5 className="mb-1">
										<Badge variant={this.props.badgeVariant}>
											{this.props.discount}% offer
										</Badge>
									</h5>
								) : (
									""
								)}
							</div>
						</Card.Body>
					</div>
				</div>
			</Card>
		);
	}
}

QuickBite.propTypes = {
	itemClass: PropTypes.string,
	title: PropTypes.string.isRequired,
	imageAlt: PropTypes.string,
	image: PropTypes.string,
	imageClass: PropTypes.string,
	showBadge: PropTypes.bool,
	badgeVariant: PropTypes.string,
	badgeText: PropTypes.string,
	badgeVariant1: PropTypes.string,
	badgeText1: PropTypes.string,
	price: PropTypes.number.isRequired,
	priceUnit: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	quantity: PropTypes.number,
	minValue: PropTypes.number,
	maxValue: PropTypes.number,
	getValue: PropTypes.func.isRequired,
};
QuickBite.defaultProps = {
	itemClass: "gold-members",
	imageAlt: "",
	imageClass: "",
	showBadge: false,
	price: "",
	priceUnit: "$",
	showPromoted: false,
	badgeVariant: "danger",
};

export default QuickBite;
