import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Image } from "react-bootstrap";
import Checkout from "./Checkout";
import DataService from "../services/DataService";
import { alertError } from "../components/Alerts";
const ORDER_TYPES = ["DELIVERY", "TAKEOUT"];
const DELIVER_PREPAIR = ["deliver", "prepare"];
const DELIVER_ADDRESS_MESSAGE = [
	"Your order will be delivered to",
	"Please pickup your order from the following address.",
];
const PAYMENT_DECLINED = 5;
const PROCESS_FAILED = 6;
const SUCESS_TRANSACTION = 7;

const getOrderTitle = (orderType) => {
	var message = "";
	if (orderType) {
		message =
			"THANK YOU. YOUR " + ORDER_TYPES[orderType] + " ORDER IS SUBMITTED.";
	}
	return message;
};

const getPrepareMessage = (orderType, phone, prep_time = "45 minutes", address) => {
	var message = "";
	if (orderType) {
		message =
			"Thank you for your order. To make any changes, or should you need further assistance, please call the restaurant " +
			"at: " +
			phone +
			"." +
			" The estimated amount of time required to " +
			DELIVER_PREPAIR[orderType] +
			" this order is " +
			prep_time +
			"minutes. Please note that quoted order times are estimations, order times can vary during peak business hours.";
	}
	return message;
};
class Thanks extends React.Component {
	constructor(props) {
		super(props);
		this.cartHandler = new DataService("carts");
		this.messageTitle = "your order is processing";
		this.message =
			" Please don't refresh this page till your request is processed";
		this.returnTitle = "Processing";

		this.returnLink = "/" + this.props.key_name + "/" + "Checkout";
		if (this.props.payment_status === "verified") {
			let result = this.addCart(false, 0, "pretent_successs");
			this.state = {
				messageTitle: "your order is processing",
				code: result.code,
				status: result.status,
				processed: result.processed,
				message:
					" Please don't refresh this page till your request is processed",
				returnTitle: "Processing",

				returnLink: "/" + this.props.key_name + "/" + "Checkout",
				customerParams: result.customerParams,
				inputParams: result.inputParams,
			};
		} else {
			this.state = {
				messageTitle: "your order is processing",
				code: 0,
				status: false,
				processed:
					this.props.payment_return &&
					this.props.payment_return == true &&
					this.props.payment_status == 0
						? true
						: false,
				message:
					" Please don't refresh this page till your request is processed",
				returnTitle: "Processing",

				returnLink: "/" + this.props.key_name + "/" + "Checkout",
			};
		}

		if (this.props.payment_return == true) {
			if (this.props.payment_status == 1) {
				this.addCart(true, this.props.order_id);
			} else if (this.props.payment_status === "verify") {
				this.addCart(
					false,
					0,
					new DataService("updateOrder").createData({
						action_type: "update_order_payment_status",
						payment_status: 1,
						cart_id: this.props.order_id,
					})
				);
			} else if (this.props.payment_status === "verified") {
				// let result = this.addCart(false, 0, "pretent_successs");
				// this.state.status = result.status;
				// this.state.code = result.code;
				// this.state.processed = result.processed;
				// this.state.customerParams = result.customerParams;
				// this.state.inputParams = result.inputParams;
			} else {
				console.log("email vals");
				console.log("email");
				console.log("trying for email");
				let inputParams = this.cartHandler.getCartParams(
					this.props.key_name
				);
				console.log("params", inputParams);

				var react_message = JSON.stringify(inputParams);
				var domain = this.props.key_name;
				var mail_params = {
					restaurant_name: this.props.key_name,
					issue_type: 2,
					issue_message:
						"Payment Failed due to declining card , Order details :" +
						react_message,
				};
				this.cartHandler.emailClientIssue(mail_params);

				this.setState(
					{ status: false, code: PAYMENT_DECLINED, processed: true },
					() => {
						console.log("trying for email");
						let inputParams = this.cartHandler.getCartParams(
							this.props.key_name
						);
						console.log("params", inputParams);

						var react_message = JSON.stringify(inputParams);
						var domain = this.props.key_name;
						var mail_params = {
							restaurant_name: this.props.key_name,
							issue_type: 2,
							issue_message:
								"Payment Failed , Order details :" + react_message,
						};
						this.cartHandler.emailClientIssue(mail_params);
					}
				);

				this.messageTitle = "";

				this.message = "The Payment Gateway has declined the transaction.";
				this.returnLink = "/" + this.props.key_name + "/" + "checkout";
				this.returnTitle = "try again";
			}
		} else {
			this.addCart();
		}
	}

	emptyCart = () => {
		this.cartHandler.setCartDisbaled(this.props.key_name);
	};

	//create api request for add cart details to server,
	addCart = (payment_status = false, payment_details = 0, apiHandler = null) => {
		let inputParams = this.cartHandler.getCartParams(this.props.key_name);

		let customerParams = JSON.parse(inputParams.customer);
		var pay_message = "payment pending order";

		console.log("parsed customer params", customerParams);
		if (customerParams) {
			if (!customerParams["payment_status"]) {
				customerParams["payment_status"] = payment_status;
			}
			pay_message = payment_status == 1 ? "paid order" : pay_message;
			customerParams["payment_details"] = payment_details;
			inputParams.customer = JSON.stringify(customerParams);
			if (!apiHandler) {
				apiHandler = this.cartHandler.createCart(inputParams);
			}
			if (apiHandler === "pretent_successs") {
				this.emptyCart();
				return {
					status: true,
					code: SUCESS_TRANSACTION,
					processed: true,
					customerParams: customerParams,
					inputParams: inputParams,
				};
				// this.setState();
			} else {
				apiHandler
					.then((response) => {
						if (
							response.data["status"] === "Sucess" ||
							response.data["status"] === "success"
						) {
							this.emptyCart();

							this.setState({
								status: true,
								code: SUCESS_TRANSACTION,
								processed: true,
								customerParams: customerParams,
								inputParams: inputParams,
							});

							// history.push(this.state.key_name+'/thanks');
						} else {
							this.messageTitle = "Sorry";
							var react_message = JSON.stringify(inputParams);
							var domain = this.props.key_name;
							var mail_params = {
								restaurant_name: this.props.key_name,
								issue_type: 1,
								issue_message:
									"Process Failed for" +
									pay_message +
									" Order details :" +
									react_message,
							};
							this.cartHandler.emailClientIssue(mail_params);
							this.emptyCart();

							if (payment_status == 1) {
								this.message =
									"We are unable to confirm the payment. Please check your credit card statement before attempting again";
							} else {
								this.message =
									"We are unable to confirm your order.";
							}
							this.returnLink =
								"/" + this.props.key_name + "/" + "checkout";
							this.returnTitle = "Home";
							this.setState({
								status: false,
								processed: true,
								customerParams: customerParams,
								inputParams: inputParams,
							});
						}
					})
					.catch((err) => {
						this.messageTitle = "Sorry";
						var react_message = JSON.stringify(inputParams);
						var domain = this.props.key_name;
						var mail_params = {
							restaurant_name: this.props.key_name,
							issue_type: 1,
							issue_message:
								"Process Failed for" +
								pay_message +
								" Order details :" +
								react_message,
						};
						this.cartHandler.emailClientIssue(mail_params);

						if (payment_status == 1) {
							this.message =
								"We are unable to confirm the payment. Please check your credit card statement before attempting again";
						} else {
							this.message = "We are unable to confirm your order.";
						}
						this.emptyCart();
						this.returnLink =
							"/" + this.props.key_name + "/" + "checkout";
						this.returnTitle = "try again";
						this.setState({
							status: false,
							processed: true,
							code: PROCESS_FAILED,
						});
					});
			}
		}
		return false;
	};

	render() {
		let phone = "";
		let prep_time = "";
		let address = "";
		let prepMessage = "";
		let delMessage = "";
		if (this.state.status == true) {
			let profile = this.cartHandler.getRestaurantProfile(
				this.props.key_name
			);
			let custParams = this.state.customerParams;
			this.messageTitle = getOrderTitle(custParams["order_mode"]);
			phone = profile.profile.phone;
			prep_time =
				profile.profile.takeout_response_time &&
				profile.profile.takeout_response_time != null
					? profile.profile.takeout_response_time
					: "45 Minute";
			prep_time =
				custParams["order_mode"] == 0 ? Number(prep_time) + 30 : prep_time;
			address =
				custParams["order_mode"] == 0
					? custParams["address"]
					: profile.profile.address;
			prepMessage = getPrepareMessage(
				custParams["order_mode"],
				phone,
				prep_time,
				address
			);
			delMessage = DELIVER_ADDRESS_MESSAGE[custParams["order_mode"]];

			this.message = prepMessage + delMessage + address;

			this.returnLink = "/" + this.props.key_name;
			this.returnTitle = "Home";
		}

		return (
			<section className="section pt-5 pb-5 osahan-not-found-page">
				<Container>
					<Row>
						<Col md={12} className="text-center pt-5 pb-5">
							{this.state.processed === false && (
								<Image
									className="img-fluid"
									src="/img/pro.gif"
									alt="404"
								/>
							)}
							{this.state.processed === true &&
								this.state.code === SUCESS_TRANSACTION && (
									<Image
										className="img-fluid"
										src="/img/thanks.png"
										alt="404"
									/>
								)}

							{this.state.processed === true &&
								this.state.code != SUCESS_TRANSACTION && (
									<Image
										className="img-fluid"
										src="/img/reject.png"
										alt="404"
									/>
								)}
							<h2 className="mt-2 mb-2">{this.messageTitle}</h2>

							{(!this.state.status || this.state.status == false) && (
								<p className="mb-5">{this.message}</p>
							)}

							{this.state.status == true && (
								<span>
									<p className="mb-5">{prepMessage}</p>
									<p>
										<b>{delMessage}</b>
									</p>
									<p>{address}</p>
								</span>
							)}

							{this.state.processed === true && (
								<Link
									className="btn btn-primary btn-lg"
									to={this.returnLink}
								>
									{this.returnTitle}
								</Link>
							)}
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}

export default Thanks;
