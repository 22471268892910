import { Helmet } from 'react-helmet';
import React from 'react';
const Seo = ({ title, description,  keywords }) => {
	return (
<Helmet htmlAttributes={{ lang: 'en' }} title={title} meta={[
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        },
		]}

    />
 );
}
export default Seo;