import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Index from "./components/Index";
import Offers from "./components/Offers";
import MyAccount from "./components/MyAccount";
import List from "./components/List";
import NotFound from "./components/NotFound";
import Thanks from "./components/Thanks";
import Extra from "./components/Extra";
import Login from "./components/Login";
import Register from "./components/Register";
import TrackOrder from "./components/TrackOrder";
import Invoice from "./components/Invoice";
import Checkout from "./components/Checkout";
import Detail from "./components/Detail";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-select2-wrapper/css/select2.css";
import "./App.css";
import { alertError } from "./components/Alerts";
import { Helmet } from "react-helmet";
import IdleTimer from "react-idle-timer";
import { browserName, deviceDetect } from "react-device-detect";

import DataService from "./services/DataService";
import DeliveryRequest from "./components/DeliveryRequest";
import MonerisPayPage from "./components/MonerisPayPage";

var key_ = "";
class App extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.idleTimer = null;

		this.state = {
			cart: [],
			cartMessage: "",
			displayCategories: [],
			cartlength: 0,
			path: "",
			payment_status: false,
		};
		this.setCategories = this.setCategories.bind(this);
	}

	setCategories = (catObj) => {
		this.setState({ displayCategories: catObj });
	};

	handleOnAction(event) {}

	handleOnActive(event) {
		console.log("user is active", key_);
		window.location.reload(false);
		//return <Redirect to={"/"+"shut"} />

		// let keyName=this.props.pathName;
		//return <Redirect to={"/"+keyName} />
	}

	handleOnIdle(event) {
		sessionStorage.clear();
		alert("Time Expired");
	}

	render() {
		console.log(this.props.location.pathname);
		let keyName = this.props.location.pathname.split("/")[1];
		key_ = keyName;
		console.log(keyName);

		let cart = new DataService("restaurants").getCartParams(keyName);

		let timeInMinutes = !window.timeInMinutes ? 30 : window.timeInMinutes;
		let second = "";
		let length = this.props.location.pathname.split("/").length;
		let disable_view_cart = false;
		if (this.props.location.pathname.split("/").length > 2) {
			second = this.props.location.pathname.split("/")[2];
			if (this.props.location.pathname.split("/").length > 3) {
				let second = this.props.location.pathname.split("/")[2];
				try {
					let fourth = this.props.location.pathname.split("/")[4];
					if (
						second === "payment" &&
						(fourth === "verify" || fourth === "verified")
					) {
						let dataHandler = new DataService("restaurants");
						let cart = dataHandler.getCartParams(keyName);

						if (!cart || cart.length === 0) {
							return <Redirect to={"/" + keyName} />;
						} else {
							let customerParams = JSON.parse(cart.customer);
							if (
								dataHandler.validateInputs(customerParams) == false
							) {
								return <Redirect to={"/" + keyName} />;
							}
						}

						disable_view_cart = true;
					}
				} catch (er) {}
			} else if (second == "thanks" || second == "payment") {
				let dataHandler = new DataService("restaurants");
				let cart = dataHandler.getCartParams(keyName);

				if (!cart || cart.length === 0) {
					return <Redirect to={"/" + keyName} />;
				} else {
					let customerParams = JSON.parse(cart.customer);
					if (dataHandler.validateInputs(customerParams) == false) {
						return <Redirect to={"/" + keyName} />;
					}
				}

				disable_view_cart = true;
			}

			if (second != "delpayment" && sessionStorage.length == 0) {
				return <Redirect to={"/" + keyName} />;
			}
		}

		return (
			<>
				{second != "delpayment" &&
				this.props.location.pathname !== "/login" &&
				this.props.location.pathname !== "/register" &&
				this.props.location.pathname !== "/invoice" ? (
					<Header
						pathLength={length}
						displayCategories={this.state.displayCategories}
						pathName={keyName}
					/>
				) : (
					""
				)}
				<IdleTimer
					ref={(ref) => {
						this.idleTimer = ref;
					}}
					timeout={1000 * 60 * timeInMinutes}
					onActive={this.handleOnActive}
					onIdle={this.handleOnIdle}
					onAction={this.handleOnAction}
					debounce={250}
				/>

				<Switch>
					<Route path="/index" exact component={NotFound} />
					{/* <Route path="/myaccount" component={MyAccount} /> */}
					<Route path="/404" exact component={NotFound} />
					{/* <Route path="/extra" exact component={Extra} /> */}

					<Route
						path="/:key_name/Checkout"
						render={(props) => {
							return (
								<Checkout key_name={props.match.params.key_name} />
							);
						}}
					/>

					<Route
						path="/:key_name/MonerisPayPage/:ticket"
						render={(props) => {
							console.log("MonerisPageProps", props.match);
							return (
								<MonerisPayPage
									name={props.match.params.key_name}
									ticket={props.match.params.ticket}
								></MonerisPayPage>
							);
						}}
					/>

					<Route
						path="/:key_name/delpayment/:order_id?/:status?"
						render={(props) => {
							let key_name = props.match.params.key_name;
							let order_id = props.match.params.order_id
								? props.match.params.order_id
								: 0;
							let payment_status = props.match.params.status;
							disable_view_cart = true;
							if (Number(payment_status) >= 100) {
								return (
									<DeliveryRequest
										payment_status={payment_status}
										payment_return={true}
										key_name={key_name}
										order_id={order_id}
									></DeliveryRequest>
								);
							} else {
								return <Redirect to={"/" + keyName} />;
							}
						}}
					/>

					<Route
						path="/:key_name/payment/:order_id?/:status?"
						render={(props) => {
							let key_name = props.match.params.key_name;
							let order_id = props.match.params.order_id
								? props.match.params.order_id
								: 0;
							let payment_status = props.match.params.status;
							disable_view_cart = true;

							return (
								<Thanks
									payment_status={payment_status}
									payment_return={true}
									key_name={key_name}
									order_id={order_id}
								/>
							);
						}}
					/>

					<Route
						path="/:key_name/thanks"
						render={(props) => {
							disable_view_cart = true;
							return (
								<Thanks
									status={true}
									key_name={props.match.params.key_name}
								/>
							);
						}}
					/>

					<Route path="/:key_name">
						<Detail
							setCategories={this.setCategories}
							pathName={this.props.location.pathname.slice(1)}
						></Detail>
					</Route>

					<Route
						path="/"
						render={(props) => {
							let keyName = new DataService("restaurants").getPath();
							if (keyName && keyName.length > 0) {
								return (
									<Detail
										setCategories={this.setCategories}
										pathName={keyName}
									></Detail>
								);
							} else {
								return <NotFound />;
							}
						}}
					/>
					<Route exact component={NotFound} />
				</Switch>
				{this.props.location.pathname !== "/login" &&
				this.props.location.pathname !== "/register" ? (
					<Footer
						disable_view_cart={disable_view_cart}
						cartMessage={this.state.cartMessage}
						cartlength={this.state.cartlength}
						pathName={keyName}
					/>
				) : (
					""
				)}
			</>
		);
	}
}

export default App;
