function getModifiedOfferItems(offerItems) {
	let modifiedOfferItems = offerItems.map((item) => ({
		...item,
		offerSelected: item.offerSelected ?? false,
	}));

	modifiedOfferItems.sort(
		(a, b) =>
			Number(a.minim_amount_to_be_purchased) -
			Number(b.minim_amount_to_be_purchased)
	);

	// when considering multiple offers remove this part
	modifiedOfferItems =
		modifiedOfferItems.length > 0 ? [modifiedOfferItems[0]] : [];
	//

	return modifiedOfferItems;
}

function getOfferRelatedCustomerMessage(offer, offerEnablingBalanceAmount) {
	let offerMessage = "";
	if (Number(offerEnablingBalanceAmount) === 0) {
		offerMessage = offer.offer_message;
	} else {
		offerMessage = `You are $${offerEnablingBalanceAmount} away from getting an offer`;
	}
	return offerMessage;
}

function getOfferEnablingBalanceAmount(offer, cartTotalItemsAmount) {
	let balanceAmount =
		Number(offer.minim_amount_to_be_purchased) - Number(cartTotalItemsAmount);

	balanceAmount = balanceAmount > 0 ? balanceAmount : 0;

	balanceAmount = balanceAmount.toFixed(2);
	return balanceAmount;
}

function getCartOfferList(params) {
	const { offers, cartTotalItemsAmount } = params;
	let modifiedOffers = [];
	for (let i = 0; i < offers.length; i++) {
		const offer = offers[i];
		let modifiedOffer = {};
		let offerReadyToSelect = false;
		let offerEnablingBalanceAmount = getOfferEnablingBalanceAmount(
			offer,
			cartTotalItemsAmount
		);
		let offerRelatedCustomerMessage = getOfferRelatedCustomerMessage(
			offer,
			offerEnablingBalanceAmount
		);
		if (Number(offerEnablingBalanceAmount) === 0) {
			offerReadyToSelect = true;
		}
		modifiedOffer = {
			...offer,
			offerEnablingBalanceAmount,
			offerRelatedCustomerMessage,
			offerReadyToSelect,
		};

		modifiedOffers.push(modifiedOffer);
	}

	return modifiedOffers;
}

export { getModifiedOfferItems, getCartOfferList };
