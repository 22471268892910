import React from "react";
import {
	Form,
	Row,
	Col,
	InputGroup,
	Modal,
	FormGroup,
	Label,
	ButtonToolbar,
	Button,
	ToggleButton,
	ToggleButtonGroup,
	Image,
} from "react-bootstrap";
import Icofont from "react-icofont";
import SlotPicker from "./SlotPicker";
import Moment from "moment";
import {
	getCurrentDate,
	getTodayString,
	toClientTime,
	findDateFromDay,
	getDateAfter,
	getDay,
	isInBetween,
	isTimeBetween,
} from "../../services/DataFormatHandler";
import TimePicker from "react-bootstrap-time-picker";
import SearchLocationInput from "../location/SearchLocationInput";
import SimpleReactValidator from "simple-react-validator";
import { css } from "jquery";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DeliveryModal extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.today = getCurrentDate("YYYY-MM-DD");
		this.maxDate = getDateAfter(4, "YYYY-MM-DD");
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });

		this.state = {
			times: [],
			minDate: this.today,
			maxDate: this.maxDate,
			showFuture: false,
			timeSlots: [],
			times: [],
			notAvailable: true,
			availableTimes: [],
		};

		this.setAddress = this.setAddress.bind(this);
	}

	isTimeAvailable = (slotObj, slots, date = Moment().format("YYYY-MM-DD")) => {
		let status = false;

		for (let i = 0; i < slots.length; i++) {
			let copentime = toClientTime(
				this.props.profile.time_zone,
				findDateFromDay(slots[i]["day"]) + " " + slots[i]["opening_time"]
			);
			let cclosetime = toClientTime(
				this.props.profile.time_zone,
				findDateFromDay(slots[i]["day"]) + " " + slots[i]["closing_time"]
			);
			let currenTime = Moment(
				date + " " + slotObj.start_time,
				"YYYY-MM-DD h:mm A"
			);
			let cStatus =
				currenTime.isSameOrAfter(Moment()) &&
				((currenTime.isSameOrAfter(copentime) &&
					currenTime.isBefore(cclosetime)) ||
					(currenTime.isSameOrBefore(cclosetime) &&
						currenTime.isSameOrAfter(copentime)));

			/*
			status=status || (isTimeBetween(slots[i].opening_time,
				slots[i].closing_time,
				slotObj.start_time))
				*/
			status = status || cStatus;
		}
		return status;
	};
	componentDidMount() {
		this.addTimeSplits();
	}

	componentDidUpdate() {
		if (!(this.state.del in [0, 1])) {
			if (
				this.props.profile.disable_takeout &&
				this.props.profile.disable_delivery === false
			) {
				this.setState({
					del: 0,
				});
				this.props.changeDeliveryMode(0);
			} else if (
				this.props.profile.disable_takeout === false &&
				this.props.profile.disable_delivery
			) {
				this.setState({
					del: 1,
				});
				this.props.changeDeliveryMode(1);
			}
		}
	}

	addTimeSplits = (timeSlotObj) => {
		let id = 1;
		let slotTime = Moment("0:0:0", "HH:mm:ss");
		let endTime = Moment("24:0:0", "HH:mm:ss");
		while (slotTime < endTime) {
			let prevTime = slotTime.format("hh:mm A");
			slotTime = slotTime.add(30, "minutes");
			let nextTime = slotTime.format("hh:mm A");
			this.state.times.push({
				id: 1,
				start_time: prevTime,
				end_time: nextTime,
			});
		}
	};

	setDayTimes() {
		console.log("Calculate Time");
		console.log(this.props.shopping_date);
		let date = Moment(this.props.shopping_date).format("YYYY-MM-DD");
		var day = getDay(date);
		var timeSlots = this.getSlots(day);
		console.log(timeSlots);

		var availableTimes = [...this.state.times].filter((slotObj) => {
			return this.isTimeAvailable(slotObj, timeSlots, date);
		});
		console.log(availableTimes);
		let status = true;
		if (availableTimes.length == 0) {
			status = false;
		}
		this.setState(
			{
				timeSlots: timeSlots,
				availableTimes: availableTimes,
				notAvailable: status,
				emptyItem: false,
			},
			() => {
				if (this.state.availableTimes.length > 0) {
					this.props.setShoppingTime(
						this.state.availableTimes[0].start_time
					);
				}
			}
		);
	}

	getSlots(day) {
		let timeSlots = [];
		let availableDaysForTakeout = this.props.profile.availableDaysForTakeout;
		let availableDaysForDelivery = this.props.profile.availableDaysForDelivery;
		if (
			this.props.profile.enable_separate_delivery_schedule &&
			this.props.del_mode == 0
		) {
			if (availableDaysForDelivery && availableDaysForDelivery[day]) {
				timeSlots = availableDaysForDelivery[day];
			}
		} else {
			if (availableDaysForTakeout && availableDaysForTakeout[day]) {
				timeSlots = availableDaysForTakeout[day];
			}
		}
		return timeSlots;

		// if (this.props.profile.available_days) {
		// 	let timeSlots = (this.props.profile.available_days[day]) ? this.props.profile.available_days[day] : []

		// 	return timeSlots;
		// }
		// return [];
	}
	setDelMode = () => {};
	setAddress(addressObject) {
		if (addressObject.formatted_address) {
			this.setState(
				{ address: addressObject },
				this.props.setShoppingAddress(addressObject)
			);
		}
	}
	validateModel = (e) => {
		console.log("va;idation");

		let modeValid = this.validator.fieldValid("delivery_mode") == true;
		console.log(modeValid);
		if (this.state.del == 0) {
			console.log(this.state.del);
			console.log(this.validator.fieldValid("address") == true);
			console.log(this.props.addressEror == false);

			modeValid =
				modeValid &&
				this.validator.fieldValid("address") == true &&
				this.props.addressEror == false;
			console.log(modeValid);
		}
		if (this.state.showFuture == true) {
			modeValid =
				modeValid &&
				this.validator.fieldValid("shopping_date") == true &&
				this.validator.fieldValid("time") == true;
		}
		if (modeValid == false) {
			this.validator.showMessages();
			return modeValid;
		} else {
			return modeValid;
		}
	};
	handleDate(date) {
		this.props.setShoppingDate(date.target.value);
	}
	setNotAvailable = (value) => {
		this.setState({ notAvailable: value });
	};
	render() {
		// console.log(this.props.profile);
		return (
			<Modal
				className={"zx"}
				show={this.props.show}
				onHide={this.props.onHide}
				centered
				contentClassName="ior-modal-content2"
			>
				<Modal.Header closeButton={false}>
					<Modal.Title as="h5" id="add-address">
						Select Order Mode
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form>
						<div className="form-row">
							<Form.Group className="col-md-12 ">
								<Row
									className="text-center  radio-toolbar"
									style={{ flexWrap: "nowrap" }}
								>
									<Col md={3}></Col>
									{this.props.profile.disable_takeout ==
										false && (
										<Col
											md={
												this.props.profile.disable_delivery
													? 0
													: 3
											}
											sm={
												this.props.profile.disable_delivery
													? 0
													: 4
											}
											className=" mt-1 mb-1"
										>
											<input
												disabled={
													this.props.profile
														.disable_delivery
												}
												type="radio"
												value={1}
												name="delivery_mode"
												onClick={(v) => {
													this.setState({
														del: v.target.value,
													});
													this.props.changeDeliveryMode(
														v.target.value
													);
												}}
												id="take_radio"
												checked={this.state.del == 1}
											/>
											<label for="take_radio">
												<Image
													fluid
													src="img/take-away.png"
												/>
												<br></br>Takeout
											</label>
										</Col>
									)}
									{this.props.profile.disable_delivery ==
										false && (
										<Col
											md={
												this.props.profile.disable_takeout
													? 0
													: 3
											}
											sm={
												this.props.profile.disable_takeout
													? 0
													: 4
											}
											className=" mt-1 mb-1"
										>
											<input
												disabled={
													this.props.profile
														.disable_takeout
												}
												type="radio"
												value={0}
												name="delivery_mode"
												onClick={(v) => {
													this.setState({
														del: v.target.value,
													});

													this.props.changeDeliveryMode(
														v.target.value
													);
												}}
												id="del_radio"
												checked={this.state.del == 0}
											/>
											<label for="del_radio">
												<Image
													fluid
													src="img/delivery-man.png"
												/>
												<br></br>Delivery
											</label>
										</Col>
									)}
									<Col md={3}></Col>
								</Row>
								<Row>
									<span
										style={{
											textAlign: "center",
											width: "100%",
										}}
									>
										{this.validator.message(
											"delivery_mode",
											this.state.del,
											"required",
											{ className: "text-danger" }
										)}
									</span>
								</Row>
								{this.state.del == 0 && (
									<span>
										<Form.Label>
											<b>Delivery Address</b>
										</Form.Label>
										<Row>
											<Col md={12}>
												<SearchLocationInput
													country={
														this.props.profile
															.country_config.map
															? this.props.profile
																	.country_config
																	.map
															: "ca"
													}
													addressDisabled={true}
													setBack={this.setAddress}
												/>
												{this.props.addressEror == true && (
													<div className="text-danger mb-2">
														<strong>!</strong> Outside
														Delivery area, please choose
														Takeout
													</div>
												)}
											</Col>
										</Row>
										<Row>
											<span
												style={{
													textAlign: "center",
													width: "100%",
												}}
											>
												{this.validator.message(
													"address",
													this.state.address,
													"required",
													{ className: "text-danger" }
												)}
											</span>
										</Row>
									</span>
								)}
								<Form.Label>
									<b>Order Type</b>
								</Form.Label>
								<Row>
									<Col md={6} sm={6} className="mb-2">
										<Button
											style={{ borderRadius: 5 }}
											type="button"
											onClick={(e) => {
												if (this.validateModel() == true) {
													this.setState(
														{
															minDate: this.today,
															maxDate: this.maxDate,
															showFuture: false,
															del: null,

															address: null,
														},
														() => {
															console.log(this.state);
															this.props.setCurrentDeliveryMode(
																e
															);
														}
													);
												}
											}}
											className="d-flex w-100 text-center justify-content-center "
										>
											Order Now
										</Button>
									</Col>
									<br></br>
									<Col md={6} sm={6}>
										<Button
											style={{ borderRadius: 5 }}
											type="button"
											onClick={(e) => {
												if (this.validateModel() == true) {
													if (
														this.state.showFuture ==
														false
													) {
														this.setState(
															{ showFuture: true },
															() => {
																this.setDayTimes();
															}
														);
														//this.props.setShoppingTime(500);
													}
												}
											}}
											className="d-flex w-100 text-center justify-content-center ordercolor"
										>
											Order For Later
										</Button>
									</Col>
								</Row>
								{this.state.showFuture == true && (
									<Row>
										<Col md={6}>
											<Form.Label>Choose Date</Form.Label>
											<DatePicker
												selected={Moment(
													this.props.shopping_date
												).toDate()}
												onChange={(date) => {
													let date_value =
														Moment(date).format(
															"YYYY-MM-DD"
														);
													var day = getDay(date_value);
													var timeSlots =
														this.getSlots(day);
													let status = true;

													var availableTimes = [
														...this.state.times,
													].filter((slotObj) => {
														return this.isTimeAvailable(
															slotObj,
															timeSlots,
															date_value
														);
													});
													if (
														availableTimes.length == 0
													) {
														status = false;
													}
													this.setState(
														{
															timeSlots: timeSlots,
															availableTimes:
																availableTimes,
															notAvailable: status,
															emptyItem: false,
														},
														() => {
															this.props.disableEmptyItem();

															this.props.setShoppingDate(
																date_value
															);
															if (
																this.state
																	.availableTimes
																	.length > 0
															) {
																this.props.setShoppingTime(
																	this.state
																		.availableTimes[0]
																		.start_time
																);
															}
														}
													);
												}}
												includeDates={[
													new Date(),
													new Date().setDate(
														new Date().getDate() + 1
													),
													new Date().setDate(
														new Date().getDate() + 2
													),
													new Date().setDate(
														new Date().getDate() + 3
													),
													new Date().setDate(
														new Date().getDate() + 4
													),
												]}
												placeholderText="Please select your  date"
											/>
										</Col>
										<Col md={4}>
											<Form.Group>
												<Form.Label>Choose Time</Form.Label>

												<SlotPicker
													value={this.props.time}
													availableTimes={
														this.state.availableTimes
													}
													onChange={(event) => {
														this.props.disableEmptyItem();
														let time =
															event.target.value;
														this.setState(
															{ emptyItem: false },
															() => {
																this.props.setShoppingTime(
																	time
																);
															}
														);
													}}
												></SlotPicker>
											</Form.Group>
										</Col>

										<Col md={2} className="mt-4">
											<Button
												style={{ borderRadius: 5 }}
												type="button"
												onClick={(e) => {
													if (
														this.state.notAvailable ==
															true &&
														this.validateModel() == true
													) {
														this.state = {};
														this.setState(
															{
																minDate: this.today,
																maxDate:
																	this.maxDate,
																showFuture: false,
																del: null,
																address: null,
															},
															() => {
																console.log(
																	this.state
																);
																this.props.setDeleveryMode(
																	e
																);
															}
														);
													}
												}}
												className="d-flex w-100 text-center justify-content-center"
											>
												SET
											</Button>
										</Col>
										<span>
											{this.validator.message(
												"shopping_date",
												this.props.shopping_date,
												"required",
												{ className: "text-danger" }
											)}
										</span>

										<span>
											{this.validator.message(
												"time",
												this.props.time,
												"required",
												{ className: "text-danger" }
											)}
										</span>
									</Row>
								)}
								{this.state.notAvailable == false && (
									<Row>
										<Col
											md={12}
											className="d-flex w-100 text-center justify-content-center"
										>
											<p className="text-danger">
												{" "}
												<Icofont icon="warning" />
											</p>
											<h6 className="text-danger">
												{" "}
												Sorry ! No slots are available on
												selected day
											</h6>
										</Col>
									</Row>
								)}
								{this.props.emptyItem && this.props.categories && (
									<div>
										<Row>
											<Col
												md={12}
												className="d-flex w-100 text-center justify-content-center"
											>
												<p className="text-danger">
													{" "}
													<Icofont icon="warning" />
												</p>
												<h6 className="text-danger">
													{" "}
													Closed, please use “Order for
													Later” for pre-order
												</h6>
											</Col>
										</Row>
										<Row>
											<Col
												md={12}
												className="d-flex w-100 text-center justify-content-center"
											>
												<p>
													{" "}
													Sorry ! No items are available
													with previous selection.
												</p>
												<p>Please choose another time.</p>
											</Col>
										</Row>
									</div>
								)}
							</Form.Group>
						</div>
					</Form>
				</Modal.Body>

				{/*}	<Modal.Footer>
					<Button type='button' variant="primary"
					onClick={this.props.setDeleveryMode}
					className='d-flex w-100 text-center justify-content-center'>SUBMIT</Button>
								</Modal.Footer>*/}
			</Modal>
		);
	}
}
export default DeliveryModal;
