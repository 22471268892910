import React from 'react';
import { Form, InputGroup, Modal,FormGroup,Label, ButtonToolbar, Button, ToggleButton, ToggleButtonGroup, Image } from 'react-bootstrap';
import Icofont from 'react-icofont';
//import Select, { components }  from 'react-select';
import Moment from 'moment';
import {toCurrentTime,isInBetween, isTimeBetween} from "../../services/DataFormatHandler";

const step=5;
class SlotPicker extends React.Component {

	constructor(props, context) {
		super(props, context); 
	}



	render() {
		
		return (

												 <Form.Control as="select" 
												 name="time_zone" 
												 onChange={this.props.onChange} >

{this.props.availableTimes.map((slotObj)=>{



return <option value={slotObj.start_time}>{slotObj.start_time}</option>})}
</Form.Control>

	
);
	


}


}
export default SlotPicker;