import React from 'react';
import {Link} from 'react-router-dom';
import {Image,Card,Media} from 'react-bootstrap';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types'; 

class SummaryCard extends React.Component {

	render() {
    	return (
    		<Card className="bg-white payments-item mb-4 shadow-sm">
            	<div className="gold-members p-4">
                  <Media>
            		<Image src={this.props.logoImage} alt={this.props.imageAlt} className={this.props.imageclassName} /> 
                     <Media.Body>
                        <h4 className="mb-1">
                        <Link className="text-success" to="#" onClick={()=>{this.props.onClick(this.props.mode)}}><Icofont icon="listing-number" /> 	{this.props.title}</Link>

                          
                          </h4>
	                     
			              
                       
                    </Media.Body>
           		  </Media>
           		</div>
            </Card>
    	);
    }
}

SummaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  logoImage: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  imageAlt: PropTypes.string,
  imageclassName: PropTypes.string,
  onClick: PropTypes.string,
};
SummaryCard.defaultProps = {
  subTitle: '',
  imageAlt: '',
  imageclassName: '',
}

export default SummaryCard;